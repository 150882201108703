import Rocketbot from '../../../assets/Pagina Soluciones Flexware/logo_rocketbot.svg'
import Progress from '../../../assets/Pagina Soluciones Flexware/logo_Progress.svg'
import NWave from '../../../assets/Pagina Soluciones Flexware/logo-waves.svg'
import Flexwarelogo from '../../../assets/Pagina Home Flexware/logocontactoflexware.svg'
import emailjs from 'emailjs-com';
import React, { useState } from 'react'

import Arpapel from '../../../assets/Customers/arpapel.svg'
import Banorte from '../../../assets/Customers/banorte.svg'
import Cydsa from '../../../assets/Customers/cydsa.svg'
import Italika from '../../../assets/Customers/italika.svg'
import Tuperware from '../../../assets/Customers/Tuperware.svg'

export function Partnerships() {
    const container2 = {
        width: '80%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }
    const texto = {
        color: '#666666',
        fontFamily: 'Carme, sans-serif',
        fontSize: '30px',
        fontWeight: '800',
        letterSpacing: '0',
        lineHeight: '100%',
        textAlign: 'center',
    }
    const slider = {
        height: 'auto',
        width: '80%',
        display: 'flex',
        justifyItems: 'center',
        overflow: 'hidden',
        margin: 'auto'
    }
    const slidetrack = {
        display: 'flex',
        // animation: 'scroll 40s linear infinite',
        // WebkitAnimation: 'scroll 40s linear infinite',
        // width: 'calc(250px*12)',
        width: '100%',
        justifyContent: 'space-between',

    }
    const slide = {
        width: '250px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const objetos1 = {
        height: '40%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
    }
    const contenedor = {
        height: '216px',
        width: '100%',
        backgroundColor: 'white',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        marginTop: '4vw',
        marginBlockEnd: '2vw'
    }

    //SCRIPT
    return (
        <div style={contenedor}>
            <section style={container2} className='contenedor2'>
                <div style={objetos1}>
                    <p style={texto}> Partnerships </p>
                </div>
                <div style={slider} className='slider'>
                    <div style={slidetrack} >
                        <div style={slide} className='slides'><img src={Rocketbot} alt="appdynamics-logo" className='logop' style={{ width: '85%' }} /></div>
                        <div style={slide} className='slides'><img src={Progress} alt="Harness-logo" className='logop' style={{ width: '70%' }} /></div>
                        <div style={slide} className='slides'><img src={NWave} alt="Harness-logo" className='logop' style={{ width: '70%' }} /></div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export function Costumers() {
    const container2 = {
        width: '99vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }
    const texto = {
        color: 'white',
        fontFamily: 'Carme, sans-serif',
        fontSize: '30px',
        fontWeight: '800',
        letterSpacing: '0',
        lineHeight: '25px',
        textAlign: 'center',
    }
    const objetos = {
        height: '40%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
    }
    const contenedor = {
        height: '216px',
        width: '100%',
        backgroundColor: '#58B14B',
        display: 'grid',
    }
    const slider = {
        height: '70%',
        width: '100%',
        display: 'flex',
        justifyItems: 'right',
        overflow: 'hidden',
    }
    const slidetrack = {
        display: 'flex',
        animation: 'scroll 40s linear infinite',
        WebkitAnimation: 'scroll 40s linear infinite',
        width: 'calc(350px*10)',
    }
    const slide = {
        width: '350px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    }
    return (
        <div style={contenedor}>
            <section style={container2} className='contenedor2' >
                <div style={objetos}>
                    <p style={texto}> <b>Customers</b> </p>
                </div>
                <div style={slider}>
                    <div style={slidetrack}>
                        <div style={slide}><img src={Italika} alt="Italika-logo" className='logo' /></div>
                        <div style={slide}><img src={Cydsa} alt="Actinver-logo" className='logo' /></div>
                        <div style={slide}><img src={Tuperware} alt="Azteca-logo" className='logo' /></div>
                        <div style={slide}><img src={Arpapel} alt="Azteca-logo" className='logop' /></div>
                        <div style={slide}><img src={Banorte} alt="Truper-logo" className='logop' /></div>

                        <div style={slide}><img src={Italika} alt="Italika-logo" className='logo' /></div>
                        <div style={slide}><img src={Cydsa} alt="Actinver-logo" className='logo' /></div>
                        <div style={slide}><img src={Tuperware} alt="Azteca-logo" className='logo' /></div>
                        <div style={slide}><img src={Arpapel} alt="Azteca-logo" className='logop' /></div>
                        <div style={slide}><img src={Banorte} alt="Truper-logo" className='logop' /></div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export function Contactanos() {
    const containerCont = {
        height: '100%',
        width: '100%',
        display: 'flex',
    }
    const containerLogo = {
        width: '300px',
        display: 'grid',
        position: 'float',
        backgroundColor: '#EFEFEF',
    }
    const logo = {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '90%',
        height: '90%'
    }
    const containerTexto = {
        width: '70%',
        height: '100%',
        fontFamily: 'Carme, sans-serif',
        letterSpacing: '0',
        lineHeight: '30px',
        backgroundColor: '#EFEFEF',

    }
    const textos = {
        fontSize: '17px',
        lineHeight: '24px'
    }
    const textosd = {
        maxWidth: '318px',
        fontSize: '17px',
        lineHeight: '24px'
    }
    const titulos = {
        fontSize: '20px',
        fontWeight: '900'
    }
    const encabezado = {
        width: '70%',
        fontSize: '30px'
    }
    const containerForm = {
        width: '40%',
        height: '555px',
        display: 'grid',

    }
    const titulosform = {
        fontFamily: 'Carme, sans-serif',
        fontSize: '20px',
        fontWeight: '1000',
        height: '100%',
        width: '100%',
        display: 'grid',
        alignItems: 'center',

    }
    const inputs = {
        height: '80%',
        width: '70%',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '20px',
        outline: 'none'

    }
    const labels = {
        display: 'flex',
        width: '90%',
        height: '48px',
        alignItems: 'center',
        borderRadius: '15px',
        justifyContent: 'space-around',
        backgroundColor: '#EFEFEF',
        marginRigth: '20px',
        marginLeft: '20px',
    }
    const labeln = {
        display: 'flex',
        width: '90%',
        height: '48px',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: '#EFEFEF',
        borderRadius: '15px',
        marginRigth: '20px',
        marginLeft: '20px',
        marginTop: '20px',

    }
    const inputsa = {
        height: '80%',
        width: '70%',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '20px',
        outline: 'none'

    }
    const labelsa = {
        display: 'flex',
        width: '90%',
        height: '48px',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: '#6666',
        marginRigth: '20px',
        marginLeft: '20px',
        color: 'white',
        borderRadius: '15px',

    }
    const inputm = {
        height: '110px',
        width: '100%',
        border: 'none',
        backgroundColor: '#EFEFEF',
        borderRadius: '15px',
        fontSize: '20px',
        outline: 'none',
        marginTop: '2%',
        fontFamily: 'Carme, sans-serif',
    }
    const labelcd = {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        borderRadius: '15px',
        marginRigth: '20px',
        marginLeft: '20px',


    }
    const labelc = {
        display: 'flex',
        width: '90%',
        height: '48px',
        alignItems: 'center',
        marginRigth: '20px',
        marginLeft: '20px'
    }
    const buttonLeft = {
        height: '40px',
        width: '50%',
        backgroundColor: '#56b14b',
        border: 'none',
        color: '#FFFFFF',
        marginRight: '23px',
        fontFamily: 'Carme, sans-serif',
        cursor: 'pointer',
        borderRadius: '10px',
    }
    const Contf = {
        display: 'flex',
        width: '50%',
    }

    const [nombre, setNombre] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [nombreValido, setNombreValido] = useState(true);
    const [empresaValida, setEmpresaValida] = useState(true);
    const [correoValido, setCorreoValido] = useState(true);
    const [telefonoValido, setTelefonoValido] = useState(true);
    const [mensajeValido, setMensajeValido] = useState(true);

    const validarNombre = (valor) => {
        setNombre(valor);
        setNombreValido(valor.trim().length > 0);
    };

    const validarEmpresa = (valor) => {
        setEmpresa(valor);
        setEmpresaValida(valor.trim().length > 0);
    };

    const validarCorreo = (valor) => {
        setCorreo(valor);
        setCorreoValido(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valor));
    };

    const validarTelefono = (valor) => {
        setTelefono(valor);
        setTelefonoValido(/^\d+$/.test(valor) && (valor.trim().length >= 10));
    };

    const validarMensaje = (valor) => {
        setMensaje(valor);
        setMensajeValido(valor.trim().length > 0);
    };

    const enviarFormulario = (event) => {
        if ((nombre.valueOf === '') && (empresa.valueOf === '') && (correo.valueOf === '') && (telefono.valueOf === '') && (mensaje.valueOf === '')) {
            console.log('etamos correctos')
        } else if (nombreValido && empresaValida && correoValido && telefonoValido && mensajeValido) {
            event.preventDefault();
            emailjs.sendForm('service_7zcveve', 'template_ATDAC', event.target, '4UC_TKX3ga8TT2fYN')
                .then((response) => {
                    console.log(response.text);
                    alert("Su mensaje a sido enviado")
                }, (error) => {
                    console.log(error.text);
                    alert("Sucedio un error en el envio pruebe mas tarde")
                })
        }
    }
    return (
        <div style={containerCont} className='blockcont'>
            <div style={Contf} className='fcont'>
                <div style={containerLogo} className='logocont'>
                    <div style={logo}>
                        <img src={Flexwarelogo} alt='ATDAClogo' />
                    </div>
                </div>
                <div style={containerTexto} className='blockcont'>
                    <div className='pcblock'>
                        <p style={encabezado}>¡Contáctanos! <b>Nosotros podemos asesorarte</b></p>
                        <b style={titulos}>Dónde nos encuentras </b>
                        <p style={textosd}>Av Nuevo León 202, Hipodromo Condesa, Cuauhtémoc, 06100, Ciudad de México.</p>
                        <b style={titulos}>Administrativo</b>
                        <p style={textos}>5576766310</p>
                        <b style={titulos}>Soporte y Ventas</b>
                        <p style={textos}>5576766311</p>
                        <b style={titulos}>Correo</b>
                        <p style={textos}> sales@flexware.mx</p>
                    </div>
                </div>
            </div>
            <div style={containerForm} className='contenedor2'>
                <form id='Contact' style={titulosform} className='formulario' onSubmit={enviarFormulario}>
                    <div style={labeln}>
                        <label >NOMBRE</label>
                        <input
                            type='text'
                            id='nombre'
                            value={nombre}
                            onChange={(e) => validarNombre(e.target.value)}
                            name='nombre'
                            style={inputs}
                            placeholder='Escriba su nombre'
                            required
                        />

                    </div>
                    {!nombreValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese un nombre válido.</span>}
                    <div style={labelsa}>
                        <label>CORREO</label>
                        <input
                            value={correo}
                            onChange={(e) => validarCorreo(e.target.value)}
                            type='text'
                            id="correo"
                            name="correo"
                            placeholder='Escriba su correo de contacto '
                            style={inputsa}
                            required
                        />
                    </div>
                    {!correoValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese un correo válido.</span>}
                    <div style={labels}>
                        <label >EMPRESA</label>
                        <input
                            type="text"
                            value={empresa}
                            onChange={(e) => validarEmpresa(e.target.value)}
                            id="empresa"
                            name="empresa"
                            placeholder='Escriba el nombre de su empresa'
                            style={inputs}
                            required
                        />
                    </div>
                    {!empresaValida && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese el nombre de su empresa</span>}
                    <div style={labels}>
                        <label  >TELEFONO</label>
                        <input
                            value={telefono}
                            onChange={(e) => validarTelefono(e.target.value)}
                            id="phone"
                            name="telefono"
                            placeholder='Escriba un teléfono de contacto'
                            style={inputs}
                            maxLength='10'
                            required
                        />
                    </div>
                    {!telefonoValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese su numero de telefono de contacto</span>}
                    <div style={labelcd}>
                        <label >MENSAJE / NECESIDAD</label>
                        <textarea
                            value={mensaje}
                            onChange={(e) => validarMensaje(e.target.value)}
                            id="message"
                            name="mensaje"
                            placeholder='Dejenos un mensaje aquí'
                            style={inputm}
                            required
                        />
                    </div>
                    {!mensajeValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese un mensaje</span>}
                    <div style={labelc}>
                        <button style={buttonLeft} >ENVIAR</button>
                    </div>
                </form>
            </div>
        </div>
    )
}


